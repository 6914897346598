import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../containers/navbar';
import Map from '../containers/map.js';
import ScrollAnimation from 'react-animate-on-scroll';


import wella from '../images/wella.png';
import system from '../images/system.png';
import olaplex from '../images/olaplex.png';
import phone from '../images/phone.png';
import location from '../images/location.png';
import makazefot from '../images/makazefot.png';
import videoFile from '../images/branka.mp4';
import gifFile from '../images/brankica.gif';


import slika1 from '../images/slika1.jpg';
import slika3 from '../images/slika3.png';

import slika4 from '../images/branka.jpg';






import {
  Container, Row, Col, Button, Form, FormGroup, Label, Input

} from 'reactstrap';


export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.sleep = this.sleep.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.state = { width: 0, height: 0 };


  }
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }


  async componentDidUpdate(prevProps) {
    if (prevProps[0].location.pathname !== this.props[0].location.pathname) {
      let currScroll = window.pageYOffset;
      let offsetTop = 0;

      if (this.props[0].location.pathname === '/angebot' && this.section1) {
        offsetTop = this.section1.offsetTop;
      }
      if (this.props[0].location.pathname === '/produkte' && this.section2) {
        offsetTop = this.section2.offsetTop;
      }

      if (this.props[0].location.pathname === '/preise' && this.section3) {
        offsetTop = this.section3.offsetTop;
      }

      if (this.props[0].location.pathname === '/mannschaft' && this.section4) {
        offsetTop = this.section4.offsetTop;
      }

      if (this.props[0].location.pathname === '/kontakt' && this.section5) {
        offsetTop = this.section5.offsetTop;
      }

      if (currScroll > offsetTop) {
        for (let i = currScroll; i >= offsetTop; i -= 20) {
          window.scrollTo(0, i);
          await this.sleep((2000 / offsetTop / 20));
        }

      } else {
        for (let i = currScroll; i < offsetTop; i += 20) {
          window.scrollTo(0, i);
          await this.sleep((2000 / offsetTop / 20));
        }
      }
    }
  }



  render() {


    return (
      <div ref={(node) => { this.windowNode = node }}>
        <Navigation {...this.props}></Navigation>

        <div className="cover">

          {this.state.width < 500 ?

            <img src={gifFile} />
            :


            this.windowNode ?
              <video width={this.windowNode.clientWidth} autoPlay loop muted playsInline>
                <source src={videoFile} type="video/mp4" />
                Your browser does not support the video tag.
</video>
              :
              null


          }
          <div className="bottom-right">
            <Container>
              <div className="sliderInfo">
                <Row>

                  <Col xl="6" lg="6" md="6" xs="6" sm="4">
                    <h1><a href="tel://044 740 03 13"><img src={phone} className="img-fluid phone" />
                      044 740 03 13</a></h1>
                    <p>frei, uns zu kontaktieren</p>

                  </Col>
                  <Col xl="6" lg="6" md="6" xs="6" sm="4">
                    <h1><img src={location} className="img-fluid location" />
                      Neumattstrasse 12</h1>
                    <p>8953 Dietikon </p>
                  </Col>

                </Row>
              </div>
            </Container>

          </div>

        </div>
        <img src={slika1} className="img-fluid slika1" />
        <div className="dienstleistungen" ref={(node) => this.section1 = node}>
          <ScrollAnimation animateOnce={true} animateIn="slideInUp">
            <h1>ANGEBOT</h1>
            <h5>Ihre Wünsche setzen wir um – denn Sie sind unser Mittelpunkt.</h5>

            <Container>
              <Row>
                <Col xl="6">

                  <h2>Frauen</h2>

                  <p>Ein Besuch bei Coiffeur Branka ist nicht nur ein Termin – sondern ein Erlebnis. Vom Ambiente des eleganten Salons über das
                  Verwöhnprogramm rund um Ihr Haar – Sie verlassen uns nach dem Motto «look great and feel better». </p>

                  <p>Wir sehen genau hin, auf den Menschen, der uns vertraut. Gesicht, Haarbeschaffenheit und aktuelle Trends – in einer ausführlichen
                   Style Beratung kreieren wir den Look, der Ihre Persönlichkeit strahlen lässt. </p>
                  <Link to="/preise">  <Button onClick={() => alert('aaa')}>Preise</Button></Link>


                </Col>
                <Col xl="6">

                  <h2>Männer</h2>


                  <p>Sie haben einen hohen Anspruch an Qualität und wissen einen makellosen Look zu schätzen, das verstehen wir.
                  Wir beraten sie kompetent, denn ein guter Haarschnitt ist ein gutes Gefühl, macht attraktiver und gehört zu einem gepflegten Äusseren. </p>

                </Col>
              </Row>
              <Row>


              </Row>
            </Container>
          </ScrollAnimation>

        </div>

        <div className="produkte" ref={(node) => this.section2 = node}>

          <Container>
            <ScrollAnimation animateOnce={true} animateIn="slideInUp">
              <h1>Wir wählen stetig nur die besten Produkte, solche die auf Ihre Haare abgestimmt sind und das perfekte Ergebnis garantieren. </h1>
            </ScrollAnimation>
            <div className="produkteContent">
              <Row className="d-flex justify-content-center">

                <Col sm="3" md="4" xl="3" lg="4" xs="12">
                  <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                    <a href="https://www.wella.com" target="_blank">  <img src={wella} className="img-fluid wella" /></a>
                  </ScrollAnimation>
                </Col>
                <Col sm="3" md="4" xl="3" lg="4" xs="12" className="offset-xl-1">
                  <ScrollAnimation animateOnce={true} animateIn="slideInUp">

                    <a href="https://www.systemprofessional.com" target="_blank">  <img src={system} className="img-fluid system" /></a>
                  </ScrollAnimation>
                </Col>
                <Col sm="3" md="4" xl="3" lg="4" xs="12" className="offset-xl-1">
                  <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                    <a href="https://olaplex.com/" target="_blank">  <img src={olaplex} className="img-fluid olaplex" /></a>
                  </ScrollAnimation>
                </Col>

              </Row>
            </div>
          </Container>
        </div>
        <img src={slika3} className="img-fluid slika3" />
        <div className="prise" ref={(node) => this.section3 = node}>
          <Container>
            <ScrollAnimation animateOnce={true} animateIn="slideInUp">
              <h1>Preise</h1>
              <Row >

                <Col xl="6" md="12" lg="6" >

                  <h2>Damen</h2>
                  <p>Waschen, Schneiden, Föhnen .................ab CHF 49.-</p>
                  <p>Waschen, Föhnen .....................................ab CHF 28.-</p>
                  <p>Meches .......................................................ab CHF 60.-</p>
                  <p>Färben ........................................................ab CHF 49.-</p>
                  <p>Tönen ..........................................................ab CHF 43.-</p>


                </Col>
                <Col xl="6" md="12" lg="6">

                  <h2>Herren</h2>
                  <p>Waschen, Schneiden, Föhnen ................ab CHF 38.-</p>
                  <p>Schneiden, Föhnen ..................................ab CHF 33.-</p>


                </Col>

                <Col xl="6" md="12" lg="6">
                  <br></br><br></br>
                  <p>Haarverlängerungen bereits ab CHF 5.- pro Strähne
            Augenbrauen zupfen ab CHF 12.-</p>


                  <br></br><br></br><br></br>
                </Col>



                <Col md="12">
                  <p style={{ textAlign: "center" }}>Augenbrauen und Wimpern färben, Augenbrauen zupfen für nur 29.- </p>
                </Col>
              </Row>


            </ScrollAnimation>
          </Container>
        </div>
        <img src={slika4} className="img-fluid slika3 slika4" />

        <div className="mannschaft" ref={(node) => this.section4 = node}>

          <Container>
            <ScrollAnimation animateOnce={true} animateIn="slideInUp">
              <h1 className="underline">Über mich</h1>
              <p>Ich liebe was ich tue und stehe hinter jedem einzelnen Produkt und jeder Leistung in meinem Salon.
                 Meine nie schwindende Passion für Looks, Farben und Schnitte sind der Grundstein um Ihre Zufriedenheit zu garantieren.
                Die Perfektion meines Handwerks sind mir wichtig, daher sind stetige Weiterbildungen und Trendauffrischungen ein Muss.</p>

              <p>Ich liebe Farben und beherrsche alle Techniken die nötig sind um Ihr perfektes Ergebnis zu erzielen. </p>

              <p>Ihr Lächeln am Ende ist meine Leidenschaft seit 21 Jahren Tagtäglich Schönes zu kreieren und mein Bestes für Ihr Wohlbefinden zu tun.</p>
              <p>Mein Team und ich freuen uns auf Sie. Seit 17 Jahren sind wir an der Neumattstrasse in der Nähe des Bahnhofs zu Hause.</p>
            </ScrollAnimation>

          </Container>
        </div>


        <div className="locationContact">

          <div className="mapaa">
            <Map {...this.props}></Map>
          </div>
          {/*
          <div className="kontakt" ref={(node) => this.section5 = node}>
            <Container>
              <h1>Einen Termin machen</h1>
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>

                  <Form>
                    <Row>
                      <Col xl="6" lg="6" md="6">
                        <FormGroup>
                          <Input type="text" name="" id="" placeholder="DEIN NAME" />

                        </FormGroup>
                      </Col>
                      <Col xl="6" lg="6" md="6">
                        <FormGroup>
                          <Input type="text" name="" id="" placeholder="TELEFONNUMMER" />
                        </FormGroup>

                      </Col>
                      <Col xl="4" lg="4" md="4">
                        <FormGroup>
                          <Input type="text" name="" id="" placeholder="BEDIENUNG" />
                        </FormGroup>

                      </Col>
                      <Col xl="4" lg="4" md="4">
                        <FormGroup>
                          <Input type="text" name="" id="" placeholder="DATUM" />
                        </FormGroup>

                      </Col>
                      <Col xl="4" lg="4" md="4">
                        <FormGroup>
                          <Input type="text" name="" id="" placeholder="ZEIT" />
                        </FormGroup>

                      </Col>
                      <Col xl="12" lg="12" md="12">
                        <FormGroup>
                          <Input type="textarea" placeholder="DEINE NACHRICHT" />

                        </FormGroup>
                      </Col>
                    </Row>


                    <Link to="#"><Button className="btn-detail">NENNE DEN BEGRIFF</Button></Link>


                  </Form>
                </Col>
              </Row>

            </Container>

          </div>*/}
        </div>


        <div className="footer" ref={(node) => this.section5 = node}>

          <Container>
            <img src={makazefot} className="img-fluid" />

            <Col xl="12" lg="12" md="12" sm="12" xs="12">
              <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                <h1>Kontaktieren Sie uns</h1>

                <Row>

                  <Col xl="4" lg="4" md="4">

                    <h2>Coiffeur Branka</h2>
                    <p>Neumattstrasse 12
           <span> 8953 Dietikon</span></p>

                  </Col>
                  <Col xl="4" lg="4" md="4">

                    <h2>Kontakt</h2>
                    <p><a href="tel://044 740 03 13">044 740 03 13</a>
           <span>info(at)coiffeur-branka.ch</span></p>

                  </Col>
                  <Col xl="4" lg="4" md="4">

                    <h2>Öffnungszeiten</h2>
                    <p>Montag bis Freitag 9.00 - 18.30 Uhr
                <span>Samstag 9.00 - 16.00 Uhr</span></p>

                  </Col>
                  <Col xl="5">

                    <div className="copy">
                      <p>Copyright © Coiffeur Branka - 2019. All Rights Reserved.
          Created by <span> <a href="https://www.novamedia.agency/">NOVA media</a></span></p>
                    </div>

                  </Col>

                </Row>
              </ScrollAnimation>
            </Col>

          </Container>
        </div>
      </div>


    );


  }

}

